var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"item"},[_c('div',{staticClass:"item-img",class:[
      _vm.index === 1
        ? 'green-pastel'
        : _vm.index === 2
        ? 'pink-pastel'
        : _vm.index === 3
        ? 'yellow-pastel'
        : _vm.index === 4
        ? 'red-pastel'
        : _vm.index === 5
        ? 'yellow-pastel'
        : 'blue-pastel' ]},[(_vm.img)?_c('img',{attrs:{"src":require(("@/assets/" + _vm.img)),"height":"25px","width":"33px"}}):_c('i',{staticClass:"fas fa-chart-pie",staticStyle:{"font-size":"24px"}})]),_c('div',{staticClass:"item-text"},[_c('div',{staticClass:"item-text__title"},[_vm._v(_vm._s(_vm.data.label))]),_c('div',{staticClass:"item-text__subtitle"},[(_vm.specialChar)?_c('span',[_vm._v("$")]):_vm._e(),_vm._v(" "+_vm._s(_vm.data.value)+" "),(_vm.specialChar)?_c('span',[_vm._v("M")]):(!_vm.specialChar)?_c('span',[_vm._v(_vm._s(_vm.specialCharText))]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }